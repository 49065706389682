import 'classlist-polyfill';
import objectFitImages from 'object-fit-images';

import responsiveVideos from './components/layout/responsiveVideos';
import validation from './components/forms/validation';
// import cookieNotification from './components/cookies/cookieNotification';
import ModalManager from './components/modals/ModalManager';
import floatingLabels from './components/forms/floating-labels';
import autosize from './components/forms/autosize';
import visibilityTriggers from './components/visibility-trigger/visibility-triggers';
import sidebarNavs from './components/sidebar-nav/sidebar-navs';
import accordions from './components/accordion/accordions';
import openingHours from './components/opening-hours/opening-hours';
import scrolldownIndicator from './components/scroll-down-indicator/init';
import testimonials from './components/testimonial-slider/testimonials';
import autoGalleries from './components/auto-gallery/auto-galleries';
import scrollGalleries from './components/scroll-gallery/scroll-galleries';

import Contact from './pages/Contact';
import Treatment from './pages/Treatment';
import Components from './pages/Components';

require('./utils/nativeConsole');
// Set js class
document.documentElement.classList.add('js');
document.documentElement.classList.remove('no-js');

// eslint-disable-next-line
window.modalManager = new ModalManager();
setTimeout(() => {
  // Layout setup
  scrollGalleries();
  responsiveVideos();
  // cookieNotification();
  floatingLabels();
  autosize();
  visibilityTriggers();
  sidebarNavs();
  accordions();
  openingHours();
  scrolldownIndicator();
  testimonials();
  autoGalleries();
  objectFitImages();
});

// Forms
validation();

// Page specific classes
const pages = {
  Contact,
  Components,
  Treatment,
};

const currentPage = document.documentElement.getAttribute('data-page');
if (currentPage) {
  const pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);

  if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
    new pages[pageClassName](); // eslint-disable-line no-new
  }
}

window.modalManager.checkModalOnPageLoad();
