export default class ScrollDownIndicator {
  constructor(element) {
    this.element = element;
    this.target =
      element.dataset.targetQuery !== '#'
        ? document.querySelector(element.dataset.targetQuery)
        : null;
    this.init();
  }

  init() {
    this.onScroll();
    this.onClick();
    this.element.classList.add('js-initialized');
  }

  onScroll() {
    const scrollHandler = () => {
      const doc = document.documentElement;
      const scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      if (scrollTop > 300) {
        this.element.classList.add('js-hidden');
      } else {
        this.element.style.opacity = 1 - scrollTop / 300;
        this.element.classList.remove('js-hidden');
      }
    };
    window.addEventListener('scroll', scrollHandler);
    scrollHandler();
  }

  onClick() {
    this.element.addEventListener('click', e => {
      e.preventDefault();
      if (!this.target) {
        window.scrollTo(0, window.innerHeight);
      } else {
        const yOffset = -200;
        const y = this.target.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    });
  }
}
