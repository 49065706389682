export default class TestimonialSlider {
  constructor(element) {
    this.element = element;
    this.activeIndex = 0;
    this.activeIndexEl = element.querySelector('.js-active-index');
    this.totalCountEl = element.querySelector('.js-total-count');
    this.quotes = [...element.querySelectorAll('.js-testimonial-slider-quote')];
    this.paginationActions = [...element.querySelectorAll('.js-pagination-action')];
    this.autoplay = false;
    this.autoplayTimeout = null;
  }

  init() {
    this.activeIndexEl.innerHTML = this.activeIndex + 1;
    this.totalCountEl.innerHTML = this.quotes.length;
    this.addEventListeners();
  }

  autoPlay() {
    if (this.autoPlay) {
      this.autoplayTimeout = setTimeout(() => {
        this.incrementIndex(1);
        this.renderNewIndex();
        this.autoPlay();
      }, 7000);
    }
  }

  incrementIndex(increment) {
    this.activeIndex = (this.activeIndex + increment) % this.quotes.length;
    if (this.activeIndex === -1) {
      this.activeIndex = this.quotes.length - 1;
    }
  }

  addEventListeners() {
    this.paginationActions.forEach(action => {
      const increment = parseInt(action.dataset.increment, 10);
      action.addEventListener('click', () => {
        this.incrementIndex(increment);
        this.renderNewIndex();
        if (this.autoplayTimeout) {
          this.autoplay = false;
          clearTimeout(this.autoplayTimeout);
        }
      });
    });
  }

  renderNewIndex() {
    const previousActive = this.element.querySelector('.js-testimonial-slider-quote.active');
    previousActive.classList.add('exiting');
    previousActive.classList.remove('active');
    setTimeout(() => {
      previousActive.classList.remove('exiting');
    }, 250);
    this.quotes[this.activeIndex].classList.add('active');
    this.activeIndexEl.innerHTML = this.activeIndex + 1;
  }
}
