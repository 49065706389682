import { gsap } from 'gsap';

export default class Contact {
  constructor() {
    this.initBackgroundAnimation();
  }

  initBackgroundAnimation() {
    const backgroundDecorationEls = [...document.querySelectorAll('.js-background-decoration')];

    backgroundDecorationEls.forEach(el => {
      gsap.to(el, {
        opacity: 0.3,
        yoyo: true,
        yoyoEase: true,
        repeat: -1,
        duration: 6,
        z: 20,
        rotateX: 10,
      });
    });
  }
}
