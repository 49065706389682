import { slideToggle } from '../../utils/DOMAnimations';

export default class Accordion {
  constructor(element) {
    this.element = element;
    this.items = [...element.querySelectorAll('.js-accordion-item')];
  }

  init() {
    this.addEventListeners();
  }

  addEventListeners() {
    this.items.forEach(item => {
      const title = item.querySelector('.js-accordion-title');
      const description = item.querySelector('.js-accordion-description');

      title.addEventListener('click', () => {
        item.classList.toggle('active');
        slideToggle(description);
      });
    });
  }
}
