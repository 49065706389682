import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

gsap.config({
  force3D: true,
});

export default class ScrollGallery {
  constructor(element) {
    this.element = element;
    this.scrollGalleryElement = this.element.querySelector('.scroll-gallery');
    this.visuals = [...this.element.querySelectorAll('.js-scroll-gallery-visual')];
    this.icon = this.element.querySelector('.js-scroll-gallery-icon');
    this.text = this.element.querySelector('.js-scrol-gallery-text');
  }

  init() {
    this.addEventListeners();
    this.initScrollEffect();
  }

  addEventListeners() {
    const onResize = () => {
      if (window.innerWidth !== this.windowWidth || window.innerWidth > 600) {
        const maxHeight = window.innerHeight - 141 - 90;
        let scale = ((100 / 68.23) * maxHeight) / this.element.getBoundingClientRect().width;
        if (scale > 1) {
          scale = 1;
        }
        this.scrollGalleryElement.style.transform = `scale(${scale})`;
        setTimeout(() => {
          ScrollTrigger.refresh(true);
        });
      }
    };
    window.addEventListener('resize', onResize);
    onResize();
    ScrollTrigger.refresh(true);
  }

  initScrollEffect() {
    const tl = gsap
      .timeline({
        scrollTrigger: {
          trigger: '.js-scroll-gallery',
          start: `center 50%+=${141 / 2}`,
          end: '+=2000',
          scrub: 1,
          pin: true,
        },
      })
      .from(
        this.visuals[0],
        {
          scale: 1.2,
          duration: 1,
          opacity: 0,
        },
        0.4,
      )
      .from(
        this.visuals[1],
        {
          scale: 1.2,
          duration: 1,
          opacity: 1,
        },
        0,
      )
      .from(
        this.visuals[2],
        {
          scale: 1.2,
          duration: 1,
          opacity: 0,
        },
        0.8,
      )
      .from(
        this.visuals[3],
        {
          scale: 1.2,
          duration: 1,
          opacity: 0,
        },
        1.2,
      )
      .from(
        this.visuals[4],
        {
          scale: 1.2,
          duration: 1,
          opacity: 0,
        },
        1.2,
      );

    if (this.icon) {
      tl.from(
        this.icon,
        {
          scale: 1.1,
          y: '-20%',
          duration: 1,
          opacity: 0,
        },
        1,
      );
    }

    if (this.text) {
      tl.from(
        this.text,
        {
          scale: 1.1,
          y: '10%',
          x: '20%',
          duration: 1,
          opacity: 0,
        },
        1.2,
      );
    }
  }
}
