import { slideToggle, slideUp } from '../../utils/DOMAnimations';

export default class VisibilityTrigger {
  constructor(element) {
    this.element = element;
    this.showQuery = element.dataset.showQuery;
    this.hideQuery = element.dataset.hideQuery;
    this.toggle = element.dataset.toggle === 'true';
    this.slide = element.dataset.slide === 'true';
  }

  init() {
    this.element.addEventListener('click', () => {
      this.onElementClick();
    });
  }

  onElementClick() {
    if (this.toggle && this.slide) {
      [...document.querySelectorAll(`${this.hideQuery}:not(${this.showQuery})`)].forEach(el => {
        slideUp(el);
      });
      [...document.querySelectorAll(this.showQuery)].forEach(el => {
        slideToggle(el);
      });
    } else if (this.toggle) {
      this.hideElements(`${this.hideQuery}:not(${this.showQuery})`);
      this.toggleElements(this.showQuery);
    } else {
      this.hideElements(this.hideQuery);
      this.showElements(this.showQuery);
    }
  }

  toggleElements(query) {
    [...document.querySelectorAll(query)].forEach(el => {
      el.classList.toggle('hide');
    });
  }

  hideElements(query) {
    [...document.querySelectorAll(query)].forEach(el => {
      el.classList.add('hide');
    });
  }

  showElements(query) {
    [...document.querySelectorAll(query)].forEach(el => {
      el.classList.remove('hide');
    });
  }
}
