export default class SidebarNav {
  constructor(element) {
    this.element = element;
    this.items = [...element.querySelectorAll('.js-sidebar-nav-item')];
    this.sections = [];
    this.items.forEach(item => {
      this.sections.push(document.getElementById(item.dataset.sectionId));
    });
    [this.currentActiveSection] = this.sections;
  }

  init() {
    this.addEventListeners();
  }

  getElementOffset(el) {
    const rect = el.getBoundingClientRect();
    return { top: rect.top, left: rect.left };
  }

  addEventListeners() {
    window.addEventListener('scroll', () => {
      this.currentActiveSection = this.sections[0]; // eslint-disable-line
      const navigationOffset = this.getElementOffset(this.element);

      if (window.offsetHeight + window.scrollTop === window.scrollHeight) {
        this.currentActiveSection = this.sections[this.sections.length - 1];
      } else {
        this.sections.forEach(section => {
          const sectionOffset = this.getElementOffset(section);
          const currentActiveSectionOffset = this.getElementOffset(this.currentActiveSection);
          if (
            sectionOffset.top - 1 <= navigationOffset.top &&
            sectionOffset.top >= currentActiveSectionOffset.top
          ) {
            this.currentActiveSection = section;
          }
        });
      }

      this.items.forEach(item => {
        const { sectionId } = item.dataset;
        if (sectionId === this.currentActiveSection.getAttribute('id')) {
          item.classList.add('active');
        } else {
          item.classList.remove('active');
        }
      });
    });
  }
}
