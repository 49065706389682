import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { isScrolledIntoView } from '../../utils/DOMAnimations';

gsap.registerPlugin(ScrollToPlugin);

export default class AutoGallery {
  constructor(element) {
    this.element = element;
    this.frame = element.querySelector('.js-auto-gallery-frame');
    this.items = [...element.querySelectorAll('.js-auto-gallery-item')];
    this.currentX = 0;
    this.tween = null;
    this.scrollSpeed = 0;
    this.newProgressOnRefresh = 0;
    this.windowWidth = window.innerWidth;
    this.isPlaying = true;
  }

  init() {
    this.autoMoveLeft();
    this.addEventListeners();
  }

  autoMoveLeft() {
    this.tween = gsap.fromTo(
      this.frame,
      { x: 0 },
      {
        x: -this.frame.scrollWidth / 2,
        duration: this.items.length * 4,
        ease: 'none',
        repeat: -1,
      },
    );
  }

  checkIfAnimationNeeded() {
    if (isScrolledIntoView(this.element)) {
      if (!this.isPlaying) {
        this.isPlaying = true;
        this.tween.play();
      }
    } else if (this.isPlaying) {
      this.isPlaying = false;
      this.tween.pause();
    }
  }

  addEventListeners() {
    window.addEventListener('scroll', () => {
      this.checkIfAnimationNeeded();
    });

    window.addEventListener('resize', () => {
      this.checkIfAnimationNeeded();
      if (window.innerWidth !== this.windowWidth) {
        this.tween.progress(0);
        this.tween.kill();
        setTimeout(() => {
          this.autoMoveLeft();
        }, 200);
      }
    });
  }
}
